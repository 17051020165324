import React, { Component } from 'react';
import * as WorksImages from '../assets/images/works' 

class Portfolio extends Component {

  constructor(props){
    super(props)
    this.state={images: []}
  }

  componentDidMount(){
    let auxArr=[]
    Object.values(WorksImages).map( image => auxArr.push(image))
    this.setState({images:auxArr})
  }

  render() {
    if (this.state.images){
      var projects = this.state.images.map( image =>
          <div style={{flex:"1", maxHeight:"400px", margin:"20px"}} key={image.slice(14,50)} >
            <img style={{width:"100%", maxHeight:"400px", objectFit:"cover"}} src={image} alt={image.slice(14,50)}/>
          </div>
          // <div key={image.slice(14,50)} className="columns portfolio-item">
          //   <div className="item-wrap">
          //     <img src={image} alt={image.slice(14,50)}/>
          //     <div className="overlay">
          //       <div className="portfolio-item-meta">
          //         <h5>{image.slice(14,50)}</h5>
          //         <p>{image.slice(14,50)}</p>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        )
    }
    
    return (
      <section id="portfolio">

        <div className="row">

          <div className="twelve columns collapsed">

            <h1>Otros de nuestros trabajos</h1>

            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {projects}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
