import React from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import ImageViewer from 'react-simple-image-viewer';
import * as ProductsImages from "../assets/images/products/bata"
import * as chalecoImages from "../assets/images/products/chaleco"

function Products(){

    const [images,setImages] = React.useState([]);
    const [chaleco, setChaleco] = React.useState([]);
    const [currentImage, setCurrentImage] = React.useState(0);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [product, setProduct] = React.useState(null)

    React.useEffect(()=>{
        var auxArr=[];
        Object.values(ProductsImages).map(
            image => auxArr.push(image)
        )
        setImages(auxArr);
        auxArr=[]
        Object.values(chalecoImages).map(
            chaleco => auxArr.push(chaleco)
        )
        setChaleco(auxArr);
    },[]); 
    
    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return(
        <section id="products" >
            <div className="row">
                <div className="twelve columns collapsed">
                    <h1>Productos</h1>
                    <div style={{alignItems:"center", width:"100%", flexDirection:"row", display:isViewerOpen?"none":"flex"}}>
                        <Slide easing="ease" transitionDuration="2250" style={{width:"50%"}} >
                            {images.map( (item,index) =>
                                <div key={item.slice(14,50)} className="each-slide" style={{display:"flex", padding:"10px", justifyContent:"center"}}>
                                    <img onClick={() => {openImageViewer(index); setProduct("bata")}} src={item} alt={item.slice(14,50)} style={{width:"100%", height:"350px", objectFit:"cover"}}/>
                                </div>
                            )}
                        </Slide>
                        <div style={{flex:1}}>
                            <h3 style={{textAlign:"center"}}>Precio por pieza: $220.00</h3>
                            <hr style={{ color: "#000000", backgroundColor: "#000000", height: 2, width:"100%" }}/>
                            <p>Batas de trabajo 80% algodón, 20% poliéster manga corta talla chica, mediana, grande y especiales</p>
                        </div>
                    </div>
                    <div style={{alignItems:"center", width:"100%", flexDirection:"row", display:isViewerOpen?"none":"flex"}}>
                        <Slide easing="ease" transitionDuration="2250" style={{width:"50%"}} >
                            {chaleco.map( (item,index) =>
                                <div key={item.slice(14,50)} className="each-slide" style={{display:"flex", padding:"10px", justifyContent:"center"}}>
                                    <img onClick={() => {openImageViewer(index); setProduct("chaleco")}} src={item} alt={item.slice(14,50)} style={{width:"100%", height:"350px", objectFit:"cover"}}/>
                                </div>
                            )}
                        </Slide>
                        <div style={{flex:1}}>
                            <h3 style={{textAlign:"center"}}>Precio por pieza: $220.00</h3>
                            <hr style={{ color: "#000000", backgroundColor: "#000000", height: 2, width:"100%" }}/>
                            <p>Chaleco varios usos poliester, antireflejante, 2 bolsas frontales con cierre, bolsa para celular, bolsas para pluma y bolsa trasera con cierre, tallas mediana, grande y especiales</p>
                        </div>
                    </div>
                    {isViewerOpen && (
                        <ImageViewer
                            src={ product==="bata"?images:chaleco }
                            currentIndex={ currentImage }
                            onClose={ closeImageViewer }
                            backgroundStyle={{marginTop:"48px"}}
                        />
                    )}
                </div>
            </div>
        </section>
    );
}

export default Products