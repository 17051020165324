import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons"

class Footer extends Component {
  render() {

    if(this.props.data){
      var networks= this.props.data.social.map(function(network){
        if(network.className==="faWhatsapp")
        return <li key={network.name}><a href={network.url}><FontAwesomeIcon icon={faWhatsapp} /></a></li>
        else if(network.className==="faFacebook")
        return <li key={network.name}><a href={network.url}><FontAwesomeIcon icon={faFacebook} /></a></li>
        else 
        return null
      })
    }

    return (
      <footer>

     <div className="row">
        <div className="twelve columns">
           <ul className="social-links">
              {networks}
           </ul>
           <ul className="copyright">
              <li>&copy; Copyright 2020 <a href="http://sitecauldron.com/">SiteCauldron</a></li>
           </ul>

        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
     </div>
  </footer>
    );
  }
}

export default Footer;
