import React, { Component } from 'react';
// import ParticlesBg  from "particles-bg";

class Header extends Component {
  render() {

   if(this.props.data){
      var name = this.props.data.name;
      var description= this.props.data.description;
   }

   return (
      <div>
      <div className="background"></div>
      <header id="home">
      {/* <ParticlesBg type="fountain" num="2" bg={true} /> */}
      <nav id="nav-wrap">
         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home" >Inicio</a></li>
	         {/* <li><a className="smoothscroll" href="#resume">Resume</a></li> */}
            <li><a className="smoothscroll" href="#products" >Productos</a></li>
            <li><a className="smoothscroll" href="#portfolio" >Más Trabajos</a></li>
            {/* <li><a className="smoothscroll" href="#contact" style={{color:"black"}}>Contact</a></li> */}
            <li><a className="smoothscroll" href="#about">Nosotros</a></li>
         </ul>
      </nav>

      <div className="row banner">
      
         <div className="banner-text">
            <h1 className="responsive-headline" >{name}</h1>
            <h3 style={{backgroundColor:"#787878a1", display: "inline", padding:"6px"}} >{description}.</h3>
         </div>
      </div>

      <p className="scrolldown"  >
         <a className="smoothscroll" href="#products" ><i className="icon-down-circle"></i></a>
      </p>
   </header>
   </div>
    );
  }
}

export default Header;
